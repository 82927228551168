.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt80 {
  margin-top: 80px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt80 {
  padding-top: 80px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb50 {
  padding-bottom: 50px;
}

.text-red {
  color: red;
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}

.show-grid {
  margin: 15px 0;
}

.huge {
  font-size: 40px;
}

.azalekoa-1 {
  color: #fff;
  background: #008000;
  padding: 1px;
  font-weight: bolder;
}

.media-list-thumb {
  float: left;
  margin-right: 6px;
  max-width: 50px;
}

fieldset {
  border: 1px solid #ccc;
  margin: 15px 0;
  min-width: 0;
  padding: 0 10px 5px;
}

legend {
  border-bottom: 0;
  font-size: 18px;
  margin-bottom: 10px;
  padding: 7px 0 0;
  width: inherit;
}

.botoi-gorria {
  background: #ff0000;
  color: #fff;
}

.editable-input .select2-container {
  width: 200px !important;
}

.form-filters {
  margin-bottom: 80px;
}

.featured-1 {
  background: #008000;
  color: #FFFFFF;
  padding: 2px 4px;
  border-radius: 3px;
}

.notification-message {
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.25) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 5px;
  padding: 15px 20px 12px;
  color: black;
  font-weight: bold;
  position: absolute;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  top: 0px;
  width: 100%;
  z-index: 9999;
}

.red-button {
  color: #fff;
  background: #ff0000;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: #428bca;
}

.btn-success.btn-outline {
  color: #5cb85c;
}

.btn-info.btn-outline {
  color: #5bc0de;
}

.btn-warning.btn-outline {
  color: #f0ad4e;
}

.btn-danger.btn-outline {
  color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
  margin-right: 5px;
}

.chat-panel .panel-body {
  height: 350px;
  overflow-y: scroll;
}

.login-panel {
  margin-top: 25%;
}

.panel-green {
  border-color: #5cb85c;
}

.panel-green .panel-heading {
  border-color: #5cb85c;
  color: #fff;
  background-color: #5cb85c;
}

.panel-green a {
  color: #5cb85c;
}

.panel-green a:hover {
  color: #3d8b3d;
}

.panel-red {
  border-color: #d9534f;
}

.panel-red .panel-heading {
  border-color: #d9534f;
  color: #fff;
  background-color: #d9534f;
}

.panel-red a {
  color: #d9534f;
}

.panel-red a:hover {
  color: #b52b27;
}

.panel-yellow {
  border-color: #f0ad4e;
}

.panel-yellow .panel-heading {
  border-color: #f0ad4e;
  color: #fff;
  background-color: #f0ad4e;
}

.panel-yellow a {
  color: #f0ad4e;
}

.panel-yellow a:hover {
  color: #df8a13;
}

.panel-heading > .btn-group {
  margin-top: -7px;
}

.flot-chart {
  display: block;
  height: 400px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: 0 0;
}

table.dataTable thead .sorting_asc:after {
  content: "\f0de";
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting_desc:after {
  content: "\f0dd";
  float: right;
  font-family: fontawesome;
}

table.dataTable thead .sorting:after {
  content: "\f0dc";
  float: right;
  font-family: fontawesome;
  color: rgba(50, 50, 50, 0.5);
}

.modal-body {
  min-height: 300px;
}

body {
  background-color: #f8f8f8;
}

#wrapper {
  width: 100%;
}

#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  background-color: #fff;
}

@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 250px;
    padding: 0 30px;
    border-left: 1px solid #e7e7e7;
  }
}

.navbar-top-links {
  margin-right: 0;
}

.navbar-top-links li {
  display: inline-block;
}

.navbar-top-links li:last-child {
  margin-right: 15px;
}

.navbar-top-links li a {
  padding: 15px;
  min-height: 50px;
}

.navbar-top-links .dropdown-menu li {
  display: block;
}

.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}

.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}

.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}

.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}

.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}

.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}

.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}

.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}

.sidebar .sidebar-nav.navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

.sidebar .sidebar-search {
  padding: 15px;
}

.sidebar ul li {
  border-bottom: 1px solid #e7e7e7;
}

.sidebar ul li a.active {
  background-color: #eee;
}

.sidebar .arrow {
  float: right;
}

.sidebar .fa.arrow:before {
  content: "\f104";
}

.sidebar .active > a > .fa.arrow:before {
  content: "\f107";
}

.sidebar .nav-second-level li,
.sidebar .nav-third-level li {
  border-bottom: 0 !important;
}

.sidebar .nav-second-level li a {
  padding-left: 37px;
}

.sidebar .nav-third-level li a {
  padding-left: 52px;
}

@media (min-width: 768px) {
  .sidebar {
    z-index: 1;
    position: absolute;
    width: 250px;
    margin-top: 51px;
  }
  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }
}

.roles-form ul.modules li {
  margin-bottom: 5px;
}
